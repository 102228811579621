<template>
  <div class="container">
    <h1 class="section-heading" v-html="t('about.headline')"></h1>
    <div class="paragraph-light" v-html="t('about.text')">
    </div>
    <div class="home-content-wrap">

      <figure role="group">
        <img src="img/urkunde-1920.jpg"
             sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 88vw, (max-width: 2042px) 94vw, 1920px"
             srcset="
             img/urkunde-500.jpg 500w,
             img/urkunde-1080.jpg 1080w,
             img/urkunde-1600.jpg 1600w,
             img/urkunde-1920.jpg 1920w"
             alt="" class="image-2"
             style="max-height:600px; margin-left: auto; margin-right: auto"
        >
        <figcaption v-html="t('intro.picture1')"></figcaption>
      </figure>

      <div class="w-layout-grid about-grid">
        <div id="w-node-_86e64837-0616-515b-4568-76c147234d34-f9017411">
          <div class="home-section-wrap">
            <h2 class="section-heading">
              <strong class="bold-text-4" v-html="t('selfmade.headline')">
              </strong>
            </h2>
            <div class="paragraph-light" v-html="t('selfmade.text')">
            </div>
          </div>
        </div>
        <img src="img/DSC_3727.jpg" id="w-node-_86e64837-0616-515b-4568-76c147234d3f-f9017411"
             sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 88vw, (max-width: 2042px) 94vw, 1920px"
             srcset="
             img/DSC_3727-p-500.jpeg 500w,
             img/DSC_3727-p-1080.jpeg 1080w,
             img/DSC_3727-p-1600.jpeg 1600w,
             img/DSC_3727.jpg 1920w"
             alt="" class="image">
      </div>
      <div class="w-layout-grid about-grid cc-about-2">
        <div id="w-node-_86e64837-0616-515b-4568-76c147234d41-f9017411">
          <div class="home-section-wrap">
            <h2 class="section-heading">
              <strong class="bold-text-5" v-html="t('juwelier.headline')">
              </strong>
            </h2>
            <div class="paragraph-light" v-html="t('juwelier.text')">
            </div>
          </div>
        </div>
        <img src="img/1989-juwelier-berlin.jpg" id="w-node-_86e64837-0616-515b-4568-76c147234d4c-f9017411"
             sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 88vw, (max-width: 2042px) 94vw, 1920px"
             srcset="
             img/1989-juwelier-berlin-p-500.jpeg 500w,
             img/1989-juwelier-berlin-p-1080.jpeg 1080w,
             img/1989-juwelier-berlin-p-1600.jpeg 1600w,
             img/1989-juwelier-berlin.jpg 1920w"
             alt="" class="image-2">
      </div>
      <figure role="group">
        <img src="img/BZ-1920.jpg"
             sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 88vw, (max-width: 2042px) 94vw, 1920px"
             srcset="
             img/BZ-500.jpg 500w,
             img/BZ-1080.jpg 1080w,
             img/BZ-1600.jpg 1600w,
             img/BZ-1920.jpg 1920w"
             alt="" class="image-2"
             style="max-height:600px; margin-left: auto; margin-right: auto"
        >
        <figcaption v-html="t('intro.picture2')"></figcaption>
      </figure>

      <div class="w-layout-grid about-grid">
        <div id="w-node-_25c27fd4-8eca-deb4-365a-72d4b2c603fe-f9017411">
          <div class="home-section-wrap">
            <h2 class="section-heading">
              <strong class="bold-text-10" v-html="t('wurzeln.headline')">

              </strong>
            </h2>
            <p class="paragraph-light" v-html="t('wurzeln.text')">
            </p>
          </div>
        </div>
        <img src="img/IMG_0110.jpg" id="w-node-_25c27fd4-8eca-deb4-365a-72d4b2c6040d-f9017411"
             sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 88vw, (max-width: 2042px) 94vw, 1920px"
             srcset="
             img/IMG_0110-p-500.jpeg 500w,
             img/IMG_0110-p-1080.jpeg 1080w,
             img/IMG_0110-p-1600.jpeg 1600w,
             img/IMG_0110.jpg 1920w"
             alt="" class="image-3">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutSlide",
  inject: ["t"]
}
</script>

<style scoped>

</style>