<template>
  <div class="images__slider">
    <CoolLightBox
      :items="images"
      :index="index"
      :fullScreen="true"
      @close="index = null">
    </CoolLightBox>
    <div
      class="images__preview"
      ref="imagePreview"
    >
      <figure role="group" v-if="false">
        <img
          class="image__preview"
          :src="activeImage.src"
          :alt="activeImage.description"
        />
        <figcaption>
          {{ activeImage.description }}
        </figcaption>
      </figure>
    </div>
    <div
      class="images__container"
    >
      <div
        class="images__container__img"
        @click="index = idx"
        v-for="(img, idx) in images"
        :key="idx"
        :style="{backgroundImage: `url(${img.src})`}"
      >
      </div>
    </div>
  </div>
</template>

<script>
import images from "../assets/imglist.json";
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: "ImagesSlide",
  components: {
    CoolLightBox
  },
  data() {
    return {
      images: images.images.sort(this.strcmp),
      activeImage: images.images.sort(this.strcmp)[0],
      index: null
    }
  },
  computed: {
    lightboxItems() {
      return this.images.map(e => e.src);
    }
  },
  methods: {
    strcmp(str1, str2) {
      return ((str1.src === str2.src) ? 0 : ((str1 > str2) ? 1 : -1));
    },
    goTo(img) {
      this.activeImage = img;
      console.log(this.$refs.imagePreview);
      window.scrollTo({
        left: 0,
        top: this.$refs.imagePreview.offsetTop - 70,
        behavior: "smooth"
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.images__slider {
  display: flex;
  flex-flow: column;

  .images__preview {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    img.image__preview {
      max-height: 450px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.images__container__img {
  cursor: pointer;
}

.images__container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  &__img {
    margin: 1rem;
    border-radius: .5rem;
    width: 200px;
    height: 200px;
    background-size: cover;
    background-position: center top;

    &.selected {
      border: solid 4px #0071ad;
    }
  }
}
</style>