<template>
  <div>
    <div class="section cc-store-home-wrap">
      <intro-slide/>
      <about-slide/>
    </div>
    <accordion-slide/>
    <div class="section">
      <akkordeon>
        <template #title>
          <div class="text-block">
            <strong class="bold-text-11" v-html="t('akkordeon.images.headline')">
            </strong>
          </div>
        </template>
        <images-slide/>
      </akkordeon>
    </div>

  </div>
</template>

<script>
import IntroSlide from "@/pages/IntroSlide";
import AboutSlide from "@/pages/AboutSlide.vue";
import AccordionSlide from "@/pages/AccordionSlide";
import ImagesSlide from "./ImagesSlide";
import Akkordeon from "../components/Akkordeon";

export default {
  name: "IndexPage",
  components: {Akkordeon, ImagesSlide, AccordionSlide, AboutSlide, IntroSlide},
  inject: [
    "t"
  ]
}
</script>

<style scoped>

</style>