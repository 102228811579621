<template>
  <div class="section">
    <akkordeon>
      <template #title>
        <div class="text-block">
          <strong class="bold-text-11" v-html="t('akkordeon.familie.headline')">
          </strong>
        </div>
      </template>

      <div class="paragraph"  v-html="t('akkordeon.familie.text')">
      </div>
    </akkordeon>

    <akkordeon>
      <template #title>
        <div class="text-block">
          <strong class="bold-text-11" v-html="t('akkordeon.geschichte.headline')">

          </strong>
        </div>
      </template>
      <div class="paragraph" v-html="t('akkordeon.geschichte.text')">

      </div>
    </akkordeon>
<!-- auskommentiert nach Telefonat mit Padar
    <akkordeon>
      <template #title>
        <div class="text-block">
          <strong class="bold-text-11" v-html="t('akkordeon.residenzen.headline')">
          </strong>
        </div>
      </template>
      <div class="paragraph" v-html="t('akkordeon.residenzen.text')">

      </div>
    </akkordeon>

-->
    <akkordeon>
      <template #title>
        <div class="text-block">
          <strong class="bold-text-11" v-html="t('akkordeon.wappen.headline')">
          </strong>
        </div>
      </template>
      <div class="paragraph" v-html="t('akkordeon.wappen.text')">

      </div>
    </akkordeon>
  </div>
</template>

<script>
import Akkordeon from "@/components/Akkordeon";

export default {
  name: "AccordionSlide",
  components: {Akkordeon},
  inject: [
    "t"
  ]
}
</script>

<style scoped>

</style>