<template>
  <div class="section">
    <div class="intro-header">
      <div class="intro-content cc-homepage">
        <div class="intro-text">
          <div
            class="heading-jumbo"
            v-html="t('intro.headline')"
          >
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {localGetter} from '@/config/CurrentLocale.js';

export default {
  name: "IntroSlide",
  methods: {
    ...localGetter
  }
}
</script>

<style lang="scss" scoped>
.intro-header {
  background-size: cover;
  background-position: 50% 50%;
}

@media screen and (max-width: 991px) {
  .intro-header {
    background-position: 25% 50%;
  }
}
</style>