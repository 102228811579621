<template>
  <div class="akk" ref="akk">
    <div class="akk-toggle" @click="toggle">
      <div class="akk-toggle-title">
        <slot name="title">

        </slot>
      </div>
      <div class="akk-toggle-toggle w-icon-dropdown-toggle">

      </div>
    </div>
    <div class="panel" ref="panel">
      <slot name="default">

      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Akkordeon",
  methods: {
    toggle() {
      let container = this.$refs.akk;
      let panel = this.$refs.panel;

      if (!container.classList.contains('active')) {
        // hop by one
        window.scrollBy(0, -1);
      }

      container.classList.toggle('active');

      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.akk {
  margin-bottom: 3rem;
}
.akk-toggle {
  cursor: pointer;
  padding: 20px 0 20px 20px;

  border-left: 6px solid #0071ad;
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  .akk-toggle-toggle {
    position: relative;
    transition: .4s;

    .active & {
      transform: rotate(180deg);
    }
  }
}

.panel {
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
}
</style>